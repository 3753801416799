.dashboard {
  padding: 40px;
  text-align: center;
  background-color: #f8f9fa; /* Hintergrundfarbe für das Dashboard */
  min-height: 100vh; /* Volle Höhe für bessere Ausrichtung */
}

h2 {
  margin-bottom: 40px;
  font-size: 36px;
  font-weight: bold;
  color: #333;
}

.pdf-boxes {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.pdf-box {
  width: 250px;
  height: 250px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.pdf-box h3 {
  font-size: 20px;
  color: #333;
  text-align: center;
}

.logout-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: red;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-btn:hover {
  background-color: darkred;
}

.pdf-preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: red;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.download-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.download-btn:hover {
  background-color: #0056b3;
}
