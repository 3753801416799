/* Styling für die Login-Seite */
.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
    background-image: url('https://your-image-url.com'); /* Beispiel für ein Hintergrundbild */
    background-size: cover;
    background-position: center;
    padding-top: 50px; /* Platz für den Zurück-Pfeil */
  }
  
  /* Styling für den Zurück-Pfeil */
  .back-arrow {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    font-size: 18px;
    color: #007bff;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  
  .back-arrow:hover {
    color: #0056b3; /* Hover-Effekt */
  }
  

  
  /* Styling für das Login-Formular */
  .login-form-container {
    background: white;
    padding: 60px;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out; /* Animations-Effekt */
  }
  
  /* Schriften und Layout für das Formular */
  .login-form-container h2 {
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: bold;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 25px;
    text-align: left;
  }
  
  .form-group label {
    font-weight: bold;
    font-size: 14px;
  }
  
  .form-control {
    height: 50px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    padding-left: 10px;
  }
  
  .btn-block {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    background-color: #007bff;
    font-size: 18px;
    color: white;
    font-weight: bold;
    border: none;
  }
  
  .btn-block:hover {
    background-color: #0056b3;
  }
  
  /* Animationen */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  